<template>
    <NuxtLink v-if="$device.isMobile" type="button" to="https://wa.me/15125249366" target="_blank"
        class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <span class="absolute -inset-1.5" />
        <span class="sr-only">Talk to us on WhatsApp</span>
        <Icon name="uil:whatsapp" color="" class="h-6 w-6" />
        <!-- <QuestionMarkCircleIcon class="h-6 w-6" aria-hidden="true" /> -->

    </NuxtLink>
    <button v-else type="button" @click="zendeskShow()" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
        <span class="sr-only">View notifications</span>
        <QuestionMarkCircleIcon class="h-6 w-6" aria-hidden="true" />
    </button>
    <!-- https://wa.me/15125249366 -->
</template>
<script setup>
import {
    BellIcon,
    ChatBubbleBottomCenterIcon,
    QuestionMarkCircleIcon
} from '@heroicons/vue/24/outline/esm/index.js'

const { zendeskShow } = useZendesk()


</script>