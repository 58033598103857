<template>
    <div>
        <header class="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
            <div class="mx-auto flex w-full max-w-7xl items-center px-4 sm:px-6 lg:px-8">
                <div class="flex h-16 justify-between">
                    <div class="flex">
                        <div class="flex flex-1 items-center gap-x-6">
                            <button type="button" class="-m-3 p-3 md:hidden" @click="mobileMenuOpen = true">
                                <span class="sr-only">Open main menu</span>
                                <Bars3Icon class="h-5 w-5 text-gray-900" aria-hidden="true" />
                            </button>
                            <NuxtLink class="flex h-8 w-auto" to="/client">
                                <span class="sr-only">NomadRx</span>
                                <div class="text-2xl font-bold mr-1 tracking-tight">
                                    Nomad<span class="text-gray-400">Rx</span>
                                </div>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 items-center justify-end gap-x-3">
                    <NavigationClientNavbarZendeskButton />
                    <NavigationClientNavbarAccountMenu />
                </div>
            </div>
            <Dialog as="div" v-if="$device.isMobile" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <div class="fixed inset-0 z-50" />
                <DialogPanel
                    class="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
                    <div class="-ml-0.5 flex h-16 items-center gap-x-6">
                        <button type="button" class="-m-2.5 p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div class="-ml-0.5">
                            <NuxtLink class="-m-1.5 block p-1.5" to="/client" @click="mobileMenuOpen = false">
                                <span class="sr-only">NomadRx</span>
                                <div class="text-2xl font-bold mr-1 tracking-tight">
                                    Nomad<span class="text-gray-400">Rx</span>
                                </div>
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="mt-2 space-y-2">
                        <NuxtLink v-for="item in clientNavigation" :to="item.href" @click="mobileMenuOpen = false"
                            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                            {{ item.name }}
                        </NuxtLink>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
        <div class="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
            <aside
                class="flex hidden sm:block overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                <nav class="flex-none px-4 sm:px-6 lg:px-0">
                    <ul role="list" class="flex gap-x-3 gap-y-3 whitespace-nowrap lg:flex-col">
                        <li v-for="item in clientNavigation">
                            <NuxtLink :to="item.href"
                                :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                <component :is="item.icon"
                                    :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                    aria-hidden="true" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </nav>
            </aside>

            <main class="py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                <slot />
            </main>
        </div>
    </div>
</template>

<script setup>
import { Dialog, DialogPanel, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon } from '@heroicons/vue/20/solid/esm/index.js'
import {
    ArrowLeftIcon,
    ArrowUturnLeftIcon,
    BellIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    CubeIcon,
    DocumentIcon,
    FingerPrintIcon,
    HomeIcon,
    ShieldCheckIcon,
    UserCircleIcon,
    UserIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline/esm/index.js'

const authStore = useAuthStore();

const { clientNavigation, userNavigation } = useClientNavigation();

const mobileMenuOpen = ref(false)
const automaticTimezoneEnabled = ref(true)
</script>