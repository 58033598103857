<template>
    <Menu as="div" class="relative -m-1.5 p-1.5">
        <div>
            <MenuButton
                class="relative rounded-full bg-white p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                <span class="absolute -inset-1.5" />
                <UserIcon class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">View notifications</span>
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
                class="absolute right-0 z-10 mt-2 min-w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="flex-none p-5 text-center bg-gray-50">
                    <UserCircleIcon alt="" class="mx-auto text-gray-400 h-8 w-8 rounded-full" />
                    <p class="text-sm leading-6 text-gray-500">{{ authUser.email }}</p>
                </div>
                <MenuItem v-for="item in userNavigation" v-slot="{ active, close }" class="min-w-max">
                <NuxtLink @click.native="close" :to="item.href"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                    {{ item.name }}
                </NuxtLink>
                </MenuItem>
                <MenuItem v-slot="{ active, close }">
                <NuxtLink @click="signOutUser"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                    Sign out
                </NuxtLink>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon } from '@heroicons/vue/20/solid/esm/index.js'
import {
    ArrowLeftIcon,
    ArrowUturnLeftIcon,
    BellIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    CubeIcon,
    DocumentIcon,
    FingerPrintIcon,
    HomeIcon,
    ShieldCheckIcon,
    UserCircleIcon,
    UserIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline/esm/index.js'

const authStore = useAuthStore();

const { signOutUser } = useFirebaseAuth();

const authUser = computed(() => authStore.authUser)
const user = computed(() => authStore.user)
const client = computed(() => authStore.client)


const { userNavigation } = useClientNavigation();

const mobileMenuOpen = ref(false)
const automaticTimezoneEnabled = ref(true)
</script>