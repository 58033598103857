import {
    HomeIcon,
    ArrowUturnLeftIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    DocumentIcon,
    ShieldCheckIcon,
    UserGroupIcon,
} from "@heroicons/vue/24/outline/esm";

import { isCurrentPathExact, startsWithPath } from "~/utils/comparisons/pathComparisons";

export function useClientNavigation() {

    const currentRoute = useRoute();
    const sidebarOpen = ref(false);

    const clientNavigation = ref([
        {
            name: "Home",
            href: "/client",
            icon: HomeIcon,
            current: isCurrentPathExact("/client", currentRoute.path),
        },
        {
            name: "Claims",
            href: "/client/claims",
            icon: DocumentIcon,
            current: startsWithPath("/client/claims", currentRoute.path),
        },
        {
            name: "Providers",
            href: "/client/providers",
            icon: UserGroupIcon,
            current: startsWithPath("/client/providers", currentRoute.path),
        },
        {
            name: "Insurance",
            href: "/client/insurance",
            icon: ShieldCheckIcon,
            current: startsWithPath("/client/insurance", currentRoute.path),
        },
        {
            name: "Billing",
            href: "/client/billing",
            icon: CreditCardIcon,
            current: startsWithPath("/client/billing", currentRoute.path),
        },
        {
            name: "Your account",
            href: "/client/settings",
            icon: Cog6ToothIcon,
            current: startsWithPath("/client/settings", currentRoute.path),
        },
        {
            name: "Back to homepage",
            href: "/",
            icon: ArrowUturnLeftIcon,
            current: isCurrentPathExact("/", currentRoute.path),
        },
    ]);

    const clientUserNavigation = ref([
        {
            name: "Your account",
            href: "/client/settings"
        },
    ]);

    watchEffect(() => {
        clientNavigation.value.forEach((item) => {
            if (item.name == "Home" || item.name == "Back to homepage") {
                item.current = isCurrentPathExact(item.href, currentRoute.path);
            } else {
                item.current = startsWithPath(item.href, currentRoute.path);
            }
        });
    });

    return {
        clientNavigation,
        clientUserNavigation,
        sidebarOpen
    };
}
